
.top-right, .bottom-left, .center{
  border-style:double;
  border-width: thick;
  border-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  margin: auto;
  background-color: black;
  overflow: hidden;
  
}



.top-right, .bottom-left{
  height: 60%;
  width: 40%;
}

.center{
  height: 50%;
  width: 30%;
  z-index: 2;
  top: 25%;
  left: 35%;
  font-size:90%;
  display: inline-block;
  white-space: nowrap;
}

.top-right{
  z-index: 1;
  top:0;
  bottom:auto;
  right: 0;
  left: auto;
}

.bottom-left{
  z-index: 1;
  top:auto;
  bottom:0;
  right: auto;
  left: 0;
}


#slide{
  background-color: grey;
  z-index: 10;
  position: absolute;
  height: 100%;
  width: 100%;
  left: -100%;
  transition: 0.5s;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: medium;
  color: black;
}

.about{
  width: 70%;
  height: 70%;
  right: 40%;
  
}


.top-left:hover #slide{
  transition: 0.5s;
  left: 0%;
}

.top-right:hover #slide{
  transition: 1s;
  left: 0%;
}

.bottom-left:hover #slide{
  transition: 1s;
  left: 0%;
}

.bottom-right:hover #slide{
  transition: 1s;
  left: 0%;
}

.container {
  min-height: 100vh;
  display: block;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: black;
  margin: auto;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
}

.LinkedIn, .Github, .CV, .Email{
  width: 15%;
  height: 15%;
  color: black
}


.icon{
  width: 100%;
  height: 100%;
  color: black;
}

.code-font{
  font-family: 'Press Start 2P', cursive;
  line-height: 200%;
}

.memoji{
  width: 65vh;
  height: 65vh;
  z-index: 3;
  position: absolute;
  bottom: -15%;
  left: 0%;
}



